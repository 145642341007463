
import { html, css } from 'lit'
import { TableBase } from 'shared/tables/table-base.component'
import { graphQL } from "shared/graphQL"
import { Assets } from 'shared/assets'
import { bulkActionClick } from 'shared/bulkActionHelpers'

class OrganizationsTable extends TableBase {

  static styles = [
    TableBase.styles,
    css`
    .table-base {
      background-color: #FFFFFF;
    }
    .c-column {
      cursor: pointer;
    }
    .c-column[data-type=icon] img {
      height: 36px;
      width: 36px;
    }
    .c-column[data-type=inheriting] {
      max-width: 129px;
    }
    .c-column[data-type=issue_flags] {
      max-width: 90px;
    }
    .c-column[data-type=issue_flags] player-issue-flag {
      width: 20px;
      height: 20px;
    }
    .c-column[data-type=player_status] {
      max-width: 145px;
    }
    .c-column[data-type=player_status] mss-dot.online {
      background-color: var(--success-color);
    }
    .c-column[data-type=player_status] mss-dot.offline {
      background-color: var(--danger-color);
    }
    .c-column[data-type=player_status] mss-dot.total {
      background-color: #323851;
   }
    .c-column[data-type=player_status] mss-dot.folder {
      color: #FFFFFF;
      min-width: 30px;
      width: unset;
      padding-left: 2px;
      padding-right: 2px;
      height: 30px;
      font-size: 13px;
   }

    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }
    `,
  ];

  static properties = {
    player_group_id: { type: String },
    allowed: {}
  };

  constructor() {
    super()

    this.route = '/system/organizations/:id/manage'
    this.dataName = 'Items'
    this.columns = [
      { key: 'bulk_action', title: '', display: 'always-left' },
      { key: 'name', title: 'Name', sortable: 'orderByName' },
      { key: 'description', title: 'Description', sortable: 'orderByDescription' },
      { key: 'userCount', title: 'Users', sortable: 'orderByUserCount' },
      { key: 'playerCount', title: 'Players', sortable: 'orderByPlayerCount' },
      { key: 'actions', title: '', display: 'always-right' },
    ]

    this.orderBy = 'orderByName'   // default
    this.orderType = 'ascending'   // default

    this.paginationEnabled = true
    this.paginationOptions.dataName = this.dataName
    this.searchEnabled = true

    this.settingsEnabled = true

    this.bulkActionsMenu = html`
      <div class="action delete"
           data-url="${Routes.system_organizations_path()}"
           data-method="delete"
           @click=${bulkActionClick}
      >
        ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
      </div>
    `;
  }

  errorHandler(errors) {
    console.log('Folderless table errors');
  }

  getData() {
    let query = `
      query organizations($filters: OrganizationFilter!) {
        organizationCount(filters: $filters)
        organizations(filters: $filters) {
          id
          name
          description
          userCount
          playerCount
        }
      }
    `;

    let variables = {
      filters: {
        pager: {
          pageSize: this.pageSize,
          page: this.page - 1,
        }
      }
    };

    if (this.orderBy && this.orderType) {
      let val = {}
      val[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
      //variables.filters += val
      variables.filters[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
    }
    if (this.searchValue) {
      variables.filters['textSearch'] = this.searchValue
    }

    graphQL.query({
      query: query,
      variables: variables,
      success: (response) => {
        if (response.errors) {
          this.data = []
          errorHandler(responese.errors)
        }
        this.totalCount = response.data.organizationCount
        this.data = response.data.organizations
      },
      error: (message) => {
        this.data = []
      },
    });
  }

  cellValue(colKey, item) {
    const allowedActions = new Set(
      (this.allowed || '')
        .split(',')
        .map(x => x.toLowerCase())
    );
    this.showDetails = allowedActions.has('show')
    let value = html``;
    if (colKey === 'bulk_action' && allowedActions.has('destroy')) {
      if (this.rowIsDisabled(item)) {
        value = html``
      } else {
        value = html`<mss-checkbox name="Player" value="${item.id}"></mss-checkbox>`
      }
    } else if (colKey === 'name') {
      value = item.name

    } else if (colKey === 'description') {
      value = item.description

    } else if (colKey === 'userCount') {
      value = item.userCount
    } else if (colKey === 'playerCount') {
      value = item.playerCount
    } else if (colKey === 'inheriting') {
      value = html`${item.inheriting ? 'Yes' : 'No'}`
    } else if (colKey === 'actions') {
      value = [
        {
          title: 'Manage',
          url: Routes.manage_system_organization_path(item.id),
          httpMethod: 'POST',
          remote: false,
          protect: 'update'
        },
        {
          title: 'Edit',
          url: Routes.edit_system_organization_path(item.id),
          httpMethod: 'GET',
          remote: false,
          protect: 'update'
        },
        {
          title: 'Delete',
          url: Routes.system_organization_path(item.id),
          httpMethod: 'DELETE',
          remote: true,
          confirm: `Are you sure you want to delete ${item.name || 'this record'}?`,
          protect: 'destroy'
        }
      ]
    }
    return value;
  }


  rowIsDisabled(item) {
    return item['deleting']
  }


  rowClass(item) {
    if (this.rowIsDisabled(item)) {
      return 'disable-row'
    }
    return ''
  }


  cellClick(key, item, event) {
    if (key !== 'bulk_action' && key !== 'favorite' && key !== 'actions') {
      window.location.href = Routes.manage_system_organization_path(item.id)
    }
  }

  generateBulkActionMenu() {
    const allowedActions = new Set(
      (this.allowed || '')
        .split(',')
        .map(x => x.toLowerCase())
    );
    if (allowedActions.has('edit')) {
      if (this.data === null || this.data === undefined)
        return false;
      else
        return this.data.length > 0 ? this.bulkActionsMenu : false;
    }
    else
      return false;
  }

  defaultTableSettings() {
    return {
      desktop: {
        visibleColumns: [
          'name',
          'description',
          'userCount',
          'playerCount'
        ]
      },
      tablet: {
        visibleColumns: [
          'name',
          'description',
          'userCount',
          'playerCount'
        ]
      },
      mobile: {
        visibleColumns: [
          'name',
          'description',
          'userCount',
          'playerCount'
        ]
      },
    }
  }
}
customElements.define('organizations-table', OrganizationsTable)
